<template>
    <div class="qa-preload">
        <div class="qa-preload__title">
            <p>質問と回答</p>
        </div>
        <div class="qa-preload__filter">
            <form action="">
                <div class="form-ctrl">
                    <select
                        name="status"
                        id="status"
                    >
                        <option value="">全ての質問</option>
                    </select>
                </div>
            </form>
            <div class="qa-preload__filter__pagination">
                <div class="preloader"></div>
            </div>
        </div>
        <div class="qa-preload__block">
            <div
                class="qa-preload__block__item"
                v-for="index in 5"
            >
                <div class="qa-preload__block__item__content">
                    <div class="preloader"></div>
                </div>
                <div class="qa-preload__block__item__info">
                    <div class="preloader"></div>
                    <div class="preloader"></div>
                </div>
                <div class="qa-preload__block__item__response">
                    <div class="qa-preload__block__item__response__content">
                        <div class="preloader"></div>
                    </div>
                    <div class="qa-preload__block__item__response__info">
                        <div class="preloader"></div>
                        <div class="preloader"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="@/presentation/components/preloader/qa-preload/QAPreload.ts">
</script>

<style lang="scss" src="@/presentation/components/preloader/qa-preload/QAPreload.scss" scoped>
</style>
