<template>
    <QAPreload v-if="state.preload"/>
    <div
        class="question_answer"
        v-if="!state.preload"
    >
        <div
            class="question_answer__title"
        >
            <p>質問と回答</p>
            <button
                class="btn-blue"
                v-if="state.isUser && state.projectStatus != 'completed' && state.projectStatus != ''"
                @click="showQuestionPopup"
            >
                質問する
            </button>
        </div>
        <div
            class="question_answer__filter"
        >
            <form action="">
                <div class="form-ctrl">
                    <select
                        name="status"
                        id="status"
                        v-model="state.status"
                        @change="getListQA('getNew')"
                    >
                        <option value="">全ての質問</option>
                        <option value="noresponse">回答なし</option>
                        <option value="response">回答あり</option>
                    </select>
                </div>
            </form>
            <div class="question_answer__filter__pagination">
                {{ state.listQA.length }}件表示 /
                {{ state.totalQA }}件中　 表示件数：
                <a
                    class="offset"
                    :class="{ active: state.projectQAPerPage == 10 }"
                    @click="changeProjectQAPerPage(10)"
                    >10</a>
                ｜
                <a
                    class="offset"
                    :class="{ active: state.projectQAPerPage == 50 }"
                    @click="changeProjectQAPerPage(50)"
                >50</a>
            </div>
        </div>
        <div
            class="question_answer__block"
        >
            <div
                class="question_answer__block__no_data"
                v-if="!state.listQA || state.listQA.length == 0"
            >
              質問はまだありません
            </div>
            <div
                v-if="state.listQA && state.listQA.length > 0"
                class="question_answer__block__item"
                v-for="qa in state.listQA"
                v-bind:key="qa.question_id"
            >
                <div class="question_answer__block__item__content">
                    <pre>{{ qa.content }}</pre>
                </div>
                <div class="question_answer__block__item__info">
                    質問者： <a @click="goToUserProfile(qa.account_id)">{{ qa.account_name }}</a>
                    <span>{{ state.transformUtil.convertDatetime(qa.date) }}</span>
                </div>
                <div
                    class="question_answer__block__item__response"
                    v-if="qa.responses && qa.responses.length > 0"
                    v-for="response in qa.responses"
                    v-bind:key="response.response_id"
                >
                    <div class="question_answer__block__item__response__content">
                        <pre>{{ response.content }}</pre>
                    </div>
                    <div class="question_answer__block__item__response__info">
                        <a @click="goToProfile(response.account_id)">{{ response.account_name }}</a>
                        <span>{{ state.transformUtil.convertDatetime(response.date) }}</span>
                    </div>
                </div>
                <div class="question_answer__block__item__button">
                    <button
                        class="btn-blue"
                        v-if="((state.isUser && qa.account_id == state.currentUser.account_id) || state.isOwner) && state.projectStatus != 'completed' && state.projectStatus != ''"
                        @click="showQuestionResponsePopup(qa.question_id)"
                    >
                        返信する
                    </button>
                </div>
            </div>
        </div>
        <button
            v-if="state.listQA.length < state.totalQA"
            class="btn-s btn-next-light-blue shadow btn-load-more"
            @click="loadMoreProjectQA"
            :disabled="state.loading"
        >
            <i v-if="state.loading" class="fas fa-circle-notch fa-spin"></i>
            続きを見る
        </button>
    </div>

    <ProjectQuestionPopup
        v-if="state.showAskModal"
        @submitQuestion="receiveQuestion"
        @close="state.showAskModal = false;"
    />

    <ProjectQuestionResponsePopup
        v-if="state.showResponseModal"
        :project-question-id="state.projectQuestionId"
        @submitQuestion="receiveQuestionResponse"
        @close="state.showResponseModal = false;"
    />

</template>

<script lang="ts" src="@/presentation/views/organization/project-detail/question-answer/QuestionAnswer.ts"></script>
<style lang="scss" src="@/presentation/views/organization/project-detail/question-answer/QuestionAnswer.scss" scoped>
</style>
