import { reactive, defineComponent, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import TransformUtil from "@/commons/TransformUtil";
import DIContainer from "@/core/DIContainer";
import OrganizationProjectInteractor from "@/domain/usecases/OrganizationProjectInteractor";
import ProjectQuestionPopup from "@/presentation/components/modals/project-question/ProjectQuestion.vue";
import ProjectQuestionResponsePopup from "@/presentation/components/modals/project-question-response/ProjectQuestionResponse.vue";
import QAPreload from "@/presentation/components/preloader/qa-preload/QAPreload.vue";
import CommonService from '@/services/CommonService';
export default defineComponent({
    name: "ProjectDetailQuestionAnswer",
    components: {
        ProjectQuestionPopup: ProjectQuestionPopup,
        ProjectQuestionResponsePopup: ProjectQuestionResponsePopup,
        QAPreload: QAPreload
    },
    setup: function () {
        var store = useStore();
        var router = useRouter();
        var route = useRoute();
        var state = reactive({
            transformUtil: TransformUtil,
            projectId: Number(route.params.id),
            listQA: [],
            totalQA: 0,
            projectQAPerPage: 10,
            page: 1,
            status: "",
            loading: false,
            preload: true,
            showAskModal: false,
            showResponseModal: false,
            projectQuestionId: 0,
            currentUser: {},
            isUser: CommonService.isUser() ? true : false,
            isOwner: false,
            projectStatus: ""
        });
        var organizationProjectInteractor = DIContainer.instance.get(OrganizationProjectInteractor);
        function changeProjectQAPerPage(number) {
            state.projectQAPerPage = number;
            localStorage.setItem("projectQAPerPage", number);
            getListQA("getNew");
        }
        function loadMoreProjectQA() {
            state.page++;
            getListQA("loadMore");
        }
        function showQuestionPopup() {
            state.showAskModal = true;
        }
        function showQuestionResponsePopup(question_id) {
            state.showResponseModal = true;
            state.projectQuestionId = question_id;
        }
        function receiveQuestion() {
            getListQA("getNew");
        }
        function receiveQuestionResponse(data) {
            data.date = TransformUtil.convertDatetime(new Date());
            data.account_name = state.currentUser.nick_name ? state.currentUser.nick_name : state.currentUser.name;
            data.account_id = state.currentUser.account_id;
            var projectQA = state.listQA.find(function (x) { return x.question_id === data.project_question_id; });
            if (projectQA) {
                projectQA.responses.push({
                    date: TransformUtil.convertDatetime(new Date()),
                    account_name: state.currentUser.nick_name ? state.currentUser.nick_name : state.currentUser.name,
                    account_id: state.currentUser.account_id,
                    content: data.content,
                    project_question_id: data.project_question_id
                });
            }
        }
        function getListQA(action) {
            if (action == "getNew") {
                state.page = 1;
            }
            var params = null;
            if (!state.status) {
                params = {
                    "project_id": state.projectId,
                    "limit": state.projectQAPerPage,
                    "page": state.page
                };
            }
            else {
                params = {
                    "project_id": state.projectId,
                    "filter": state.status,
                    "limit": state.projectQAPerPage,
                    "page": state.page,
                };
            }
            if (action == "loadMore") {
                state.loading = true;
            }
            return organizationProjectInteractor.getOrganizationProjectQuestion(params)
                .then(function (result) {
                if (!result.data || result.total_count == 0) {
                    state.listQA = [];
                    state.totalQA = 0;
                    state.loading = false;
                    state.preload = false;
                    return;
                }
                ;
                if (action == "getNew" || action == "onMounted") {
                    state.listQA = result.data;
                    state.preload = false;
                }
                else {
                    state.listQA = state.listQA.concat(result.data);
                }
                state.totalQA = result.total_count;
                store.commit("setQuestion", state.totalQA);
                state.loading = false;
            }).catch(function (error) {
                state.loading = false;
                state.preload = false;
            });
        }
        function goToProfile(account_id) {
            if (account_id === store.state.projectInfo.project_owner_account_id) {
                goToOrganizationProfile(account_id);
            }
            else {
                goToUserProfile(account_id);
            }
        }
        function goToUserProfile(account_id) {
            router.push("/user/profile/" + account_id);
        }
        function goToOrganizationProfile(account_id) {
            router.push("/organization/" + account_id);
        }
        onMounted(function () {
            var projectQAPerPage = localStorage.getItem("projectQAPerPage");
            state.projectQAPerPage = projectQAPerPage ? parseInt(projectQAPerPage) : 10;
            getListQA("onMounted");
            if (store.state.projectInfo && Object.keys(store.state.projectInfo).length > 0 && store.state.projectInfo.constructor === Object) {
                var user = CommonService.getCookie("user");
                if (user) {
                    state.currentUser = JSON.parse(decodeURIComponent(user));
                    state.isOwner = store.state.projectInfo.project_owner_account_id === state.currentUser.account_id;
                    state.projectStatus = store.state.projectInfo.progress_status;
                }
            }
            store.subscribe(function (mutation, stateVuex) {
                if (mutation.type === "setProjectInfo") {
                    var user = CommonService.getCookie("user");
                    if (user) {
                        state.currentUser = JSON.parse(decodeURIComponent(user));
                        state.isOwner = store.state.projectInfo.project_owner_account_id === state.currentUser.account_id;
                        state.projectStatus = stateVuex.projectInfo.progress_status;
                    }
                }
            });
        });
        return {
            state: state,
            getListQA: getListQA,
            changeProjectQAPerPage: changeProjectQAPerPage,
            loadMoreProjectQA: loadMoreProjectQA,
            showQuestionPopup: showQuestionPopup,
            showQuestionResponsePopup: showQuestionResponsePopup,
            receiveQuestion: receiveQuestion,
            receiveQuestionResponse: receiveQuestionResponse,
            goToProfile: goToProfile,
            goToUserProfile: goToUserProfile,
            goToOrganizationProfile: goToOrganizationProfile
        };
    }
});
