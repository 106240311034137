import LanguageUtil from "@/commons/LanguageUtil";
import { defineComponent, reactive } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useForm, useField } from "vee-validate";
import * as yup from "yup";
import ErrorMessage from "@/presentation/components/error-message/ErrorMessage.vue";
import Textarea from "@/presentation/components/textarea/Textarea.vue";
import OrganizationProjectInteractor from "@/domain/usecases/OrganizationProjectInteractor";
import DIContainer from "@/core/DIContainer";
import * as agh from "agh.sprintf";
export default defineComponent({
    name: "ProjectQuestionPopup",
    components: {
        Textarea: Textarea,
        ErrorMessage: ErrorMessage
    },
    setup: function (props, _a) {
        var emit = _a.emit;
        var router = useRouter();
        var route = useRoute();
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            errorResponseQuestionMessage: null,
            isSubmitting: false,
        });
        var organizationProjectInteractor = DIContainer.instance.get(OrganizationProjectInteractor);
        var _b = useForm(), isSubmitting = _b.isSubmitting, values = _b.values, errors = _b.errors, handleSubmit = _b.handleSubmit;
        var content = useField("content", yup
            .string()
            .required(state.aLang.validation_required)
            .max(1000, agh.sprintf(state.aLang.validation_max_length, 1000))
            .transform(function (value, originalValue) {
            return originalValue.trim() === "" ? "" : value;
        })).value;
        var onSubmit = handleSubmit(function () {
            state.isSubmitting = true;
            values.project_id = Number(route.params.id);
            return organizationProjectInteractor
                .postOrganizationProjectQuestion(values)
                .then(function (result) {
                state.isSubmitting = false;
                emit('submitQuestion', values);
                emit('close');
            }, function (error) {
                state.isSubmitting = false;
                state.errorResponseQuestionMessage = error.message;
            });
        });
        return {
            state: state,
            values: values,
            errors: errors,
            onSubmit: onSubmit
        };
    }
});
