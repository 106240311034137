<template id="modal-template">
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <div class="modal-body">
                        <slot name="body">
                            <div class="project__question__container">
                                <div class="project__question__container-title">
                                    質問作成
                                </div>
                                <form
                                    class="horizontal"
                                    @submit="onSubmit"
                                >
                                    <div class="form-ctrl" >
                                        <div class="form-group">
                                            <Textarea
                                                maxlength="1000"
                                                placeholder="質問内容を入力ください"
                                                v-model="values.content"
                                            />
                                            <ErrorMessage
                                                v-if="errors.content"
                                                :message="errors.content"
                                            />
                                        </div>
                                    </div>

                                    <ErrorMessage
                                        v-if="
                                            state.errorResponseQuestionMessage
                                        "
                                        :message="
                                            state.errorResponseQuestionMessage
                                        "
                                    />

                                    <div
                                        class="project__question__container-button"
                                    >
                                        <button
                                            @click="$emit('close')"
                                            type="button"
                                            class="btn-light-gray btn-md shadow"
                                        >
                                            キャンセル
                                        </button>
                                        <button
                                            :disabled="state.isSubmitting"
                                            :class="{
                                                submitting: state.isSubmitting
                                            }"
                                            type="submit"
                                            class="btn-blue btn-md sweep-to-top"
                                        >
                                            <i
                                                v-if="state.isSubmitting"
                                                class="fas fa-circle-notch fa-spin"
                                            ></i>
                                            質問する
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script lang="ts" src="@/presentation/components/modals/project-question/ProjectQuestion.ts" />
<link type="scss" scoped src="@/presentation/components/modals/project-question/ProjectQuestion.scss" />
